<template>
  <div>
    <!-- 돌발이력  -->

    <b-card class="col-12 d-flex flex-column" no-body style="min-height: 85vh">
      <div
        style="
          height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;
          z-index: 999;
          background-color: rgba(22, 29, 49, 0.63);
          opacity: 1;
          margin-left: -1.5rem;
        "
        v-show="loader"
      >
        <img
          :src="require('./loader.svg')"
          style="margin-left: 43%; margin-top: 23%"
        />
      </div>
      <b-list-group class="my-2">
        <b-list-group-item>
          <b-row align-v="center">
            <!-- 관제대상 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0017") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <v-select
                    v-model="tunnelCodeStatus"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(tunnelCodeOptions)"
                    return-object
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- 검색 기간 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0047") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <!-- style="width:320px" -->
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="mergeDate"
                      ref="dateFrom"
                      class="form-control"
                      :placeholder="$t('message.M0007')"
                      :config="flatPickrConfig"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- 방면 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0087") }}</label
                  >
                </b-col>
                <b-col md="9" >
                  <b-button-group >
                    <b-button
                      v-model="searchBound"
                      @click="searchBound = 'ALL'"
                      variant="outline-primary"
                      :pressed="searchBound == 'ALL' ? true : false"
                      >{{ $t("label.L0021") }}</b-button
                    >
                    <b-button
                      v-model="searchBound"
                      @click="searchBound = '0'"
                      variant="outline-primary"
                      :pressed="searchBound == '0' ? true : false"
                      class="auto-width-button"
                      >{{ $t("label.L0020") }}</b-button
                    >
                    <b-button
                      v-model="searchBound"
                      @click="searchBound = '1'"
                      variant="outline-primary"
                      :pressed="searchBound == '1' ? true : false"
                      class="auto-width-button"
                      >{{ $t("label.L0019") }}</b-button
                    >
                </b-button-group>
                  <!-- <v-select
                    v-model="searchBound"
                    :clearable="false"
                    label="text"
                    :reduce="(d)=>d.data"
                    :options="searchOptionsOne"
                  /> -->
                 
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row align-v="center">
            <!-- 검지 돌발 유형 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0048") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <v-select
                    v-model="eventStatus"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(eventStatusOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- 실제 돌발 유형 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0049") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <v-select
                    v-model="realEventStatus"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(realEventStatusOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- 판정 결과 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0050") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <v-select
                    v-model="judgment"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(resultOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row align-v="center">
            <!-- 장비 종류 -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0051") }}</label
                  >
                </b-col>
                <b-col md="9">
                  <v-select
                    v-model="equipType"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(equipTypeOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- 검색 조건  -->
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label
                    for=""
                    style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    "
                    >{{ $t("label.L0054") }}</label
                  >
                </b-col>
                <!-- <b-col md="3">
                  <b-row align-v="center">
                    <b-button-group
                      style="margin-left: -3.1em; margin-right: -3.1em"
                    >
                      <b-button
                        v-model="searchType"
                        @click="searchType = 'position'"
                        variant="outline-primary"
                        :pressed="searchType == 'position' ? true : false"
                        >{{ $t("label.L0052") }}</b-button
                      >
                      <b-button
                        v-model="searchType"
                        @click="searchType = 'equipName'"
                        variant="outline-primary"
                        :pressed="searchType == 'equipName' ? true : false"
                        >{{ $t("label.L0054") }}</b-button
                      >
                    </b-button-group>
                  </b-row>
                </b-col> -->
                <b-col  md="9" >
                  <b-row align-v="center">
                    <b-col style="padding-right: 0; padding-left: 0">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="filterInput"
                          :placeholder="$t('message.M0005')"
                          v-model="filter"
                          type="search"
                          @keyup.enter="doFilter"
                        />
                        <b-input-group-append>
                          <b-button variant="primary" @click="doFilter">
                            {{ $t("button.B0008") }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- 검색어 입력 -->
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
        <!-- <b-list-group-item>
          <b-row align-v="center">
            <b-col md="9" style="padding-left: 1em">
              <b-row align-v="center">
                <label for="" style="font-size: 1rem; padding-right: 2em"
                  >실제 돌발 일괄 수정</label
                >
                <b-form-radio-group
                  v-model="changeType"
                  :options="updateOptions"
                  name="radio-validation"
                >
                </b-form-radio-group>
                <b-col>
                  <b-button variant="primary" @click="emailSendTest()"
                    >이메일 테스트
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="3">
              <b-row align-v="center">
                <b-col align="right" style="padding-right: 0em">
                  <b-button variant="primary" @click="videoDownLoadBtn">
                    <feather-icon icon="FilmIcon" /> 영상 다운
                  </b-button>
                </b-col>
                <b-col align="right" style="padding-left: 0em">
                  <b-button variant="primary" @click="ExcelDownLoadBtn()">
                    <feather-icon icon="FileIcon" /> 엑셀 다운
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="12" align="center">
              <label for="" style="font-size: 1rem; font-weight: 550">{{
                eventStatisticsToString
              }}</label>
            </b-col>
          </b-row>
        </b-list-group-item> -->
      </b-list-group>
      <b-row class="padding-base-12 flex-grow-1">
        <b-table
          ref="maintable"
          striped
          hover
          responsive
          :items="getList"
          :fields="tableColumns"
          :per-page="rowNum"
          :current-page="currentPage"
          show-empty
          :empty-text="$t('message.M0008')"
          :empty-filtered-text="$t('message.M0009')"
          class="mb-0 text-center"
          style="max-height: 100%"
        >
          <template #cell(idx)="data">
            {{ data.index + 1 + (currentPage - 1) * 10 }}
          </template>

          <template #cell(location)="data">
            <b-badge pill variant="light-success">
              {{ data.item.location }}
            </b-badge>
          </template>

          <!-- <template #head(check)>
            <b-form-checkbox
              v-model="allSelected"
              :value="itemLength"
              :unchecked-value="false"
              inline
              @change="toggleAll"
            />
          </template> -->

          <template #head(check)>
            <b-form-checkbox
              v-model="allSelected"
              :value="itemLength"
              :unchecked-value="false"
              inline
              @change="toggleAll"
            />
          </template>

          <template #cell(check)="data">
            <b-form-checkbox
              v-model="temporaryItems"
              inline
              :value="data.item"
              :unchecked-value="''"
              @change="toggleOne(data)"
            />
          </template>

          <!-- <template #cell(check)="data">
            <b-form-checkbox
              v-model="temporaryItems"
              inline
              :value="data.item"
              :unchecked-value="''"
              @change="toggleOne(data)"
            />
          </template> -->

          <template #cell(confirm)="data">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="confirmToRow(data)"
              >{{ $t("button.B0012") }}</b-button
            >
          </template>
          <template #cell(confirmType)="data">
            <b-badge
              pill
              variant="light-success"
              style="width: 66.46px"
              v-if="data.item.confirmType == 1"
            >
              {{ $t("label.L0041") }}
            </b-badge>
            <b-badge
              style="width: 66.46px"
              pill
              variant="light-danger"
              v-else-if="data.item.confirmType == 2"
              >{{ $t("label.L0042") }}</b-badge
            >
            <b-badge
              pill
              variant="light-warning"
              v-else-if="data.item.confirmType == 3"
              >{{ $t("label.L0089") }}</b-badge
            >
            <!-- <b-badge
              style="width: 66.46px"
              pill
              variant="light-secondary"
              v-else-if="data.item.confirmType == 0"
              >미정</b-badge
            > -->
            <b-badge
              style="width: 66.46px"
              pill
              variant="light-secondary"
              v-else-if="data.item.confirmType == 4"
              >{{ $t("label.L0090") }}</b-badge
            >
            <b-badge
              pill
              variant="light-danger"
              v-else-if="data.item.confirmType == 999"
              >{{ $t("label.L0050") }}</b-badge
            >
          </template>
          <template #cell(occurTime)="data">
            {{ data.item.occurTime.substring(0, 23) }}
          </template>
          <template #cell(linkCode)="data">
            <b-badge
              pill
              variant="light-primary"
              v-if="data.item.direction.includes('down')"
            >
              {{ $t("label.L0020") }}
            </b-badge>
            <b-badge pill variant="light-warning" v-else>
              {{ $t("label.L0019") }}
            </b-badge>
          </template>
          <template #cell(tech)="data">
            <b-badge pill variant="light-primary" v-if="data.item.tech == 'R'">
              Radar
            </b-badge>
            <b-badge
              pill
              variant="light-primary"
              v-else-if="data.item.tech == 'S'"
            >
              Sound
            </b-badge>
            <b-badge
              pill
              variant="light-primary"
              v-else-if="data.item.tech == 'C'"
            >
              CCTV
            </b-badge>
          </template>
        </b-table>
      </b-row>

      <b-row class="my-2" align-v="center" style="bottom: 0; width: 100%">
        <b-col md="1" class="ml-1">
          <b-form-group class="mb-0">
            <v-select
              id="perPageSelect"
              v-model="rowNum"
              size="sm"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          {{ $t("label.L0021") }} {{ dataMeta.of }} {{ $t("label.L0111") }}
          {{ dataMeta.from }} ~ {{ dataMeta.to }} {{ $t("label.L0112") }}
        </b-col>

        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            :per-page="rowNum"
            @change="(temporaryItems = []), (allSelected = false)"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <event-modal
      ref="showPop"
      :propTitle="propTitle"
      :propType="propType"
      @update-obj="updateEmit"
    />
  </div>
</template>
<script>
import vSelect from "vue-select";
import dayjs from "dayjs";
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import eventModal from "./components/eventPopup.vue";

import eventInterface from "./eventInterface";

import {
  BRow,
  BCol,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BTable,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
  BButtonGroup,
  BBadge,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";

export default {
  name: "DataRetentionPolicyManagement",
  components: {
    BRow,
    BCol,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BButtonGroup,
    BBadge,
    vSelect,
    BCard,
    BListGroup,
    BListGroupItem,
    flatPickr,
    BFormCheckbox,
    eventModal,
    BSpinner,
    BFormFile,
  },
  data() {
    return {
      loader: false,
      boundNameString: {
        0: () => {
          {
            this.$t("label.L0020");
          }
        },
        1: () => {
          {
            this.$t("label.L0019");
          }
        },
      },
      deepLinkCode: "",
      items: [],
      temporaryItems: [],
      videoDownLoadItems: [],
      mergeDate: "",
      dateSeparator: "",
      propTitle: this.$t("label.L0002"),
      propType: "update",
      allSelected: false,
      resultOptions: [
        {
          text: this.$t("label.L0021"),
          data: 999,
        },
        {
          text: this.$t("label.L0041"),
          data: 1,
        },
        {
          text: this.$t("label.L0042"),
          data: 2,
        },
        {
          text: this.$t("label.L0089"),
          data: 3,
        },
        {
          text: this.$t("label.L0090"),
          data: 4,
        },
      ],
      eventStatusOptions: [
        {
          text: this.$t("label.L0021"),
          data: 999,
        },
        {
          text: this.$t("label.L0022"),
          data: 2,
        },
        {
          text: this.$t("label.L0023"),
          data: 3,
        },
        // {
        //   text: this.$t("label.L0024"),
        //   data: 6,
        // },
        {
          text: this.$t("label.L0025"),
          data: 4,
        },
        {
          text: this.$t("label.L0026"),
          data: 9,
        },
        {
          text: this.$t("label.L0027"),
          data: 1,
        },
        // {
        //   text: this.$t("label.L0091"),
        //   data: 11,
        // },
        // {
        //   text: this.$t("label.L0092"),
        //   data: 12,
        // },
        // {
        //   text: this.$t("label.L0093"),
        //   data: 13,
        // },
        // {
        //   text: this.$t("label.L0094"),
        //   data: 14,
        // },
      ],
      realEventStatusOptions: [
        {
          text: this.$t("label.L0021"),
          data: 999,
        },
        {
          text: this.$t("label.L0095"),
          data: 0,
        },
        {
          text: this.$t("label.L0022"),
          data: 2,
        },
        {
          text: this.$t("label.L0023"),
          data: 3,
        },
        // {
        //   text: this.$t("label.L0024"),
        //   data: 6,
        // },
        {
          text: this.$t("label.L0025"),
          data: 4,
        },
        {
          text: this.$t("label.L0026"),
          data: 9,
        },
        {
          text: this.$t("label.L0027"),
          data: 1,
        },
        // {
        //   text: this.$t("label.L0091"),
        //   data: 11,
        // },
        // {
        //   text: this.$t("label.L0092"),
        //   data: 12,
        // },
        // {
        //   text: this.$t("label.L0093"),
        //   data: 13,
        // },
        // {
        //   text: this.$t("label.L0094"),
        //   data: 14,
        // },
      ],
      equipTypeOptions: [
        {
          text: this.$t("label.L0021"),
          data: "ALL",
        },
        {
          text: this.$t("label.L0031"),
          data: "C",
        },
        {
          text: this.$t("label.L0029"),
          data: "R",
        },
        // {
        //   text: this.$t("label.L0030"),
        //   data: "S",
        // },
      ],
      searchOptions: [
        {
          text: this.$t("label.L0021"),
          data: "all",
        },
        {
          text: this.$t("label.L0052"),
          data: "position",
        },
        {
          text: this.$t("label.L0054"),
          data: "equipName",
        },
      ],
      searchOptionsOne: [
        {
          text: this.$t("label.L0021"),
          data: "ALL",
        },
        {
          text: this.$t("label.L0020"),
          data: "0",
        },
        {
          text: this.$t("label.L0019"),
          data: "1",
        },
      ],
      updateOptions: [
        {
          text: this.$t("label.L0027"),
          value: 1,
        },
        {
          text: this.$t("label.L0026"),
          value: 9,
        },
      ],
      config: {
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
        defaultDate: new dayjs().subtract(7, "d").format("YYYY-MM-DD HH:mm"),
        plugins: [
          // https://chmln.github.io/flatpickr/plugins/
          new ConfirmDatePlugin({
            // confirmIcon: '<feather-icon icon="CalendarIcon"/>', // your icon's html, if you wish to override
            confirmText: "OK",
            confirmIcon: "",
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      secondConfig: {
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
        defaultDate: new dayjs().format("YYYY-MM-DD HH:mm"),
        plugins: [
          // https://chmln.github.io/flatpickr/plugins/
          new ConfirmDatePlugin({
            // confirmIcon: '<feather-icon icon="CalendarIcon"/>', // your icon's html, if you wish to override
            confirmText: "OK",
            confirmIcon: "",
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      flatPickrConfig: {
        mode: "range",
        locale: this.$i18n.locale == "ko" ? Korean : null,
        enableTime: true,
        // time_24hr : true, // 오전, 오후 없어짐
        allowInput: false, // 사용자 직접 입력 막기
        // allowInput: true,
        // time_24hr: true,
        dateFormat: "Y-m-d H:i",
        maxDate: new Date(),
        defaultHour: [5, 10],
        defaultDate: new dayjs().format("YYYY-MM-DD HH:mm"),
        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "",
            confirmText: "OK", // 달력 버튼
            showAlways: true,
            theme: "light", // or "dark"
          }),
        ],
      },
      // 엑셀 변수
      excelParam: null,
    };
  },
  setup() {
    const {
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      updateFunction,
      emailTestFunction,
      searchBound,
      tunnelCodeStatus,
      eventStatus,
      realEventStatus,
      judgment,
      equipType,
      searchType,
      searchStartDate,
      searchEndDate,
      linkCode,
      changeType,
      eventStatisticsToString,
      downloadExcelFunction,
      itemLength,
      downLoadFunction,
      updateRowFunction,
      downLoadVideoListFunction,
      updateREventTypeFunction,
      getSelectBoxItems,
      tunnelCodeOptions,
    } = eventInterface();

    return {
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      filter,
      maintable,
      deleteFunction,
      updateFunction,
      emailTestFunction,
      searchBound,
      tunnelCodeStatus,
      eventStatus,
      realEventStatus,
      judgment,
      equipType,
      searchType,
      searchStartDate,
      searchEndDate,
      changeType,
      eventStatisticsToString,
      downloadExcelFunction,
      itemLength,
      downLoadFunction,
      updateRowFunction,
      downLoadVideoListFunction,
      updateREventTypeFunction,
      getSelectBoxItems,
      tunnelCodeOptions,
    };
  },
  methods: {
    sortedItems(items) {
      return items.slice().sort((a, b) => {

        if (this.$i18n.locale == 'ko') {
          if (a.text == "전체") return -1;
          if (b.text == "전체") return 1;
        } else if (this.$i18n.locale == 'en') {
          if (a.text == "All") return -1;
          if (b.text == "All") return 1;
        }

        // 이름을 기준으로 오름차순 정렬
        return a.text.localeCompare(b.text); // 음수: a가 b보다 앞, 0: a와 b가 동일, 양수: a가 b보다 뒤
      });
    },
    // async normalAct() {
    //   let tmpT = await this.getList();
    //   tmpT.forEach((each, index) => {
    //     let cnt = 0;
    //     for (let i in this.temporaryItems) {
    //       if (index == this.temporaryItems[i]) {
    //         each.checked = true;
    //         cnt++;
    //       }
    //     }
    //     cnt == 0 ? (each.checked = false) : "";
    //   });
    //   tmpT = tmpT
    //     .filter((event) => event.checked)
    //     .map((event) => {
    //       let data = {};
    //       data.no = event.no;
    //       data.rEventType = event.rEventType;
    //       data.confirmType = 1;
    //       data.memo = event.memo;
    //       return data;
    //     });
    //   await this.updateFunction(tmpT);
    //   this.temporaryItems = [];
    //   this.allSelected = false;
    //   await this.refetchData();
    // },
    getCurrentDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      month = month < 10 ? "0" + month : month;
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;

      return (
        year.toString() +
        month.toString() +
        day.toString() +
        hour.toString() +
        minute.toString() +
        second.toString()
      );
    },

    async ExcelDownLoadBtn() {
      var fileName = "돌발_리포트_" + this.getCurrentDate() + ".xlsx";
      this.loader = true;
      const response = await this.downloadExcelFunction(
        this.excelParam,
        fileName
      );
      if (response == 200) {
        this.loader = false;
      }
    },

    async selectDirectory() {
      try {
        const directoryHandle = await window.showDirectoryPicker();
        // const entries = await directoryHandle.getFile(); // 혹은 다른 파일 작업 수행

        // 디렉토리의 {{$t("label.L0021")}} 경로 가져오기
        // const fullPath = await this.getDirectoryFullPath(directoryHandle);
        try {
          console.log("Full Directory Path:", directoryHandle.getParent());
        } catch (err) {
          console.log("Full Directory Path err :", err);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getDirectoryPath(directoryHandle) {
      const pathParts = [];
      let currentHandle = directoryHandle;

      while (currentHandle) {
        pathParts.unshift(currentHandle.name);
        try {
          currentHandle = await currentHandle.getParent();
        } catch (err) {
          console.log(err);
          currentHandle = null;
        }
      }

      return "/" + pathParts.join("/");
    },

    async videoDownLoadBtn() {
      let fileUrl = "";

      try {
        if (this.temporaryItems.length == 0) {
          this.$swal({
            heightAuto: false,
            title: this.$t("message.M0012"),
            // title: "다운로드 영상을 <br/> 선택해 주세요.",
            icon: "info",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: this.$t("button.B0001"),
            cancelButtonText: this.$t("button.B0002"),
            reverseButtons: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary ml-1",
              cancelButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
          return "";
        }

        // const dirpath = await window.showDirectoryPicker();
        // this.selectDirectory();
        // console.log("dirpath : " , dirpath);
        this.temporaryItems.forEach(async (item) => {
          this.loader = true;
          const response = await this.downLoadVideoListFunction(item);
          if (response.data.success) {
            var fileName = "돌발영상_" + item.no + "_" + this.getCurrentDate();
            var data = response.data.data;
            let byteArray = data.byteArray; // 영상 byte
            var binaryString = window.atob(byteArray); // 바이트 코드를 디코드해 문자열로 변경
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen); // 새 배열 생성
            for (var i = 0; i < binaryLen; i++) {
              var ascii = binaryString.charCodeAt(i); //아스키코드 반환
              bytes[i] = ascii;
            }
            // const blob = new Blob([bytes, bytes], { type: "application/zip" });
            const blob = new Blob([bytes], { type: "video/mp4" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              //IE : IE 브라우저인경우 실행 (다른 브라우저와 동작 방식이 다르기 때문 )
              window.navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement("a"); // 다운로드를 위한 가상 태그 생성
              link.href = window.URL.createObjectURL(blob); // 사용자가 파일에 접근할 수 있도록 Blob 객체에 대한 주소 생성
              link.setAttribute("download", fileName); // 가상 태그에 다운로드 기능 추가
              link.click(); // 가상태그 클릭으로 파일에 접근해 다운로드
              link.remove(); // 가상 태그 삭제
            }

            // fileUrl = await URL.createObjectURL(blob);
            fileUrl = response.config.params.recVideoUrl;
            // await  this.$refs.showPop.getUrlFunctio(fileUrl);
            this.loader = false;
          } else {
            //  기존 dt 화면은 alert 창
            //  await  this.$refs.showPop.getUrlFunctio('');
            console.log("response err : ", response.data);
          }
        });
      } catch (err) {
        console.log("videoDownLoadBtn Error :", err);
      } finally {
        this.temporaryItems = [];
        this.allSelected = false;
      }
      return fileUrl;
    },

    async rEventTypeUpdateBtn() {
      var succ = 0;
      var fail = 0;
      try {
        if (this.temporaryItems.length > 0) {
          // const dEventTypeChk = this.temporaryItems.find((item) => item.dEventType === 1 || item.dEventType === 9);
          // if(dEventTypeChk != undefined){
          //     await this.$swal({
          //       heightAuto: false,
          //       title: "검지 돌발 유형이<br/>{{ $t("label.L0027") }}, {{ $t("label.L0026") }}만<br/>일괄 수정이 가능합니다.",
          //       icon: "info",
          //       buttons: true,
          //       showCancelButton: false,
          //       confirmButtonText: "확인",
          //       cancelButtonText: "취소",
          //       reverseButtons: true,
          //       allowEscapeKey: false,
          //       allowOutsideClick: false,
          //       customClass: {
          //         confirmButton: "btn btn-primary ml-1",
          //         cancelButton: "btn btn-outline-danger",
          //       },
          //       buttonsStyling: false,
          //   });
          //   return;
          // }
          await this.$swal({
            heightAuto: false,
            title: this.$t("message.M0013"),
            icon: "info",
            buttons: true,
            showCancelButton: true,
            confirmButtonText: this.$t("button.B0001"),
            cancelButtonText: this.$t("button.B0002"),
            reverseButtons: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary ml-1",
              cancelButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              for (const item of this.temporaryItems) {
                const response = await this.updateREventTypeFunction({
                  changeType: this.changeType,
                  no: item.no,
                });
                if (response.data.success) {
                  succ++;
                } else {
                  fail++;
                }
              }
              await this.$swal({
                heightAuto: false,
                title:
                  this.$t("message.M0014") +
                  succ +
                  this.$t("message.M0015") +
                  fail +
                  this.$t("label.L0040"),
                icon: "info",
                buttons: true,
                showCancelButton: false,
                confirmButtonText: this.$t("button.B0001"),
                cancelButtonText: this.$t("button.B0002"),
                reverseButtons: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary ml-1",
                  cancelButton: "btn btn-outline-danger",
                },
                buttonsStyling: false,
              });
            }
          });
          return "";
        } else {
          this.$swal({
            heightAuto: false,
            title: this.$t("message.M0016"),
            icon: "info",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: this.$t("button.B0001"),
            cancelButtonText: this.$t("button.B0002"),
            reverseButtons: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary ml-1",
              cancelButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
          return "";
        }
      } catch (err) {
        console.log("rEventTypeUpdateBtn Error :", err);
      } finally {
        this.temporaryItems = [];
        this.allSelected = false;
        await this.refetchData();
      }
    },

    async toggleAll(data) {
      // 매개변수는 v-model 의 값이 넘어온다.
      // checkbox 가 체크되는 경우 :value 값인 itemLength 의 10이 넘어온다.
      // checkbox 가 해제되는 경우 :unchecked-value 값인 false가 allSelected에 세팅되어 매개변수는 false가 된다.

      // 현재 페이지 모든 값 가져오기
      let tmpT = await this.getList();

      if (data) {
        this.temporaryItems = tmpT;
      } else {
        this.temporaryItems = [];
      }
    },

    // async toggleAll(data) {
    //   let tmpT= await this.getList();

    //   if (data) {
    //     var tmpList = [];
    //     for (let i = 0; i < data; i++) {
    //       tmpList.push(i);
    //     }
    //     this.temporaryItems = tmpList;
    //      tmpT.forEach((each)=>{
    //       each.checked=true
    //      })
    //   } else {
    //     tmpT.forEach((each)=>{
    //       each.checked=false
    //      })
    //     this.temporaryItems = [];
    //   }
    // },
    toggleOne(item) {
      this.temporaryItems.sort(function (a, b) {
        return a - b;
      });
    },

    async doFilter() {
      this.linkCode = this.deepLinkCode;
      this.searchStartDate = this.mergeDate.split(this.dateSeparator)[0];
      this.searchEndDate = this.mergeDate.split(this.dateSeparator)[1];
      let linktmp = "";
      // linktmp =
      //   this.searchBound == "ALL"
      //     ? null
      //     : this.deepLinkCode + (this.searchBound == "0" ? "-MCT" : "-CMT");
      linktmp =
        this.tunnelCodeStatus == "ALL"
          ? null
          : this.deepLinkCode +
            (this.searchBound == "0" ? "E-" : "S-") +
            this.tunnelCodeStatus;
      this.linkCode = linktmp;
      this.currentPage = 1;
      var searchString = this.filter;
      if (this.searchType == "position" && searchString !== "") {
        searchString = Number(this.filter) / 1000;
      }

      // 엑셀 파라미터 변수에 담기
      this.excelParam = {
        tunnelCode: this.tunnelCodeStatus,
        linkCode: this.linkCode,
        // boundType: this.searchBound,
        boundType:
          this.searchBound == "ALL"
            ? "LNK"
            : this.searchBound == 0
            ? "E-"
            : "S-",
        eventType: this.eventStatus,
        realEventType: this.realEventStatus,
        confirmType: this.judgment,
        equipType: this.equipType,
        searchType: this.searchType,
        searchString: searchString,
        startDate: this.searchStartDate,
        endDate: this.searchEndDate,
        tunnelBoundType:
          this.searchBound == "ALL"
            ? this.tunnelCodeStatus
            : this.searchBound == 0
            ? "E-" + this.tunnelCodeStatus
            : "S-" + this.tunnelCodeStatus,
      };
      this.refetchData();
    },

    createBtn() {
      this.$refs.create.createpopup();
    },
    async deleteToRow(data) {
      this.$swal({
        heightAuto: false,
        title: this.$t("message.M0006"),
        icon: "info",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: this.$t("button.B0001"),
        cancelButtonText: this.$t("button.B0002"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.deleteCall(data);
          this.refetchData();
        }
      });
    },
    async deleteCall(data) {
      await this.deleteFinal(data.item.dataTypeCode);
      this.refetchData();
    },

    // 정오검사 버튼 클릭 시 호출
    async confirmToRow(item) {
      // item : 돌발이력List 중 선택한 행관련 데이터
      // 정오검사 확인 팝업창 url 연결안됫을시 얼러트창 연결해야함
      // const returnUrl = ''
      // var returnUrl ='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
      // await this.$refs.showPop.updatepopup(item,returnUrl);
      this.loader = true;
      // const returnUrl = await this.downLoadF(item);
      this.loader = false;
      await this.$refs.showPop.updatepopup(item.item);

      // 정오검사 비디오 다운로드를 돌발 팝업창 실행 후 실행되게 설정
      // await this.$refs.showPop.updatepopup(item.item,item);
    },

    // 정오검사 비디오 다운로드
    async downLoadF(item) {
      let fileUrl = "";

      try {
        const response = await this.downLoadFunction(item);

        if (response.data.success) {
          var data = response.data.data;
          let byteArray = data.byteArray; // 영상 byte
          var binaryString = window.atob(byteArray); // 바이트 코드를 디코드해 문자열로 변경
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen); // 새 배열 생성
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i); //아스키코드 반환
            bytes[i] = ascii;
          }

          // const blob = new Blob([bytes, bytes], { type: "application/zip" });
          const blob = new Blob([bytes], { type: "video/mp4" });
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   //IE : IE 브라우저인경우 실행 (다른 브라우저와 동작 방식이 다르기 때문 )
          //   window.navigator.msSaveBlob(blob, "WytestVideo");
          // } else {
          //   let link = document.createElement("a"); // 다운로드를 위한 가상 태그 생성
          //   link.href = window.URL.createObjectURL(blob); // 사용자가 파일에 접근할 수 있도록 Blob 객체에 대한 주소 생성
          //   link.setAttribute("download", "WytestVideo"); // 가상 태그에 다운로드 기능 추가
          //   link.click(); // 가상태그 클릭으로 파일에 접근해 다운로드
          //   link.remove(); // 가상 태그 삭제
          // }

          // fileUrl = await URL.createObjectURL(blob);
          fileUrl = response.config.params.recVideoUrl;
          // await  this.$refs.showPop.getUrlFunctio(fileUrl);
        } else {
          //  기존 dt 화면은 alert 창
          //  await  this.$refs.showPop.getUrlFunctio('');
        }
      } catch (err) {
        console.log("downLoadF Error :", err);
      }
      return fileUrl;
    },
    async updateEmit(data) {
      await this.updateRowFunction(data);
      await this.refetchData();
    },
    setUpdateDate() {
      if (this.$i18n.locale == 'ko') {
        this.flatPickrConfig.locale = Korean
        // this.timeFlatPickrConfig.locale = Korean
        this.dateSeparator = ' ~ '
      } else {
        this.flatPickrConfig.locale = null
        // this.timeFlatPickrConfig.locale = null
        this.dateSeparator = ' to '
      }
      this.mergeDate = `${this.searchStartDate}${this.dateSeparator}${this.searchEndDate}`; // 기간
    },
  },
  async created() {
    // var roadConfig = await this.$storage.data[this.$storage.data["local"]];
    // this.deepLinkCode = roadConfig[0].linkCode;
    this.deepLinkCode = "LNK-0450";
    // 디폴트 날짜 ( 1달 )
    // var dateFrom = dayjs().subtract(1, "month").format("YYYY-MM-DD HH:mm");

    // 디폴트 날짜 ( 오늘 )
    var dateFrom = dayjs().startOf("day").format("YYYY-MM-DD HH:mm");
    var dateTo = dayjs().format("YYYY-MM-DD HH:mm");
    if (this.$i18n.locale == "ko") {
      this.dateSeparator = " ~ ";
    } else {
      this.dateSeparator = " to ";
    }
    this.mergeDate = `${dateFrom}${this.dateSeparator}${dateTo}`; // 기간
    this.searchStartDate = dateFrom;
    this.searchEndDate = dateTo;
    // await this.refetchData();
    this.excelParam = {
      tunnelCode: this.tunnelCodeStatus,
      linkCode: this.linkCode,
      // directionType : this.selectedDirectionType,
      // boundType: this.searchBound,
      boundType:
        this.searchBound == "ALL" ? "LNK" : this.searchBound == 0 ? "E-" : "S-",
      eventType: this.eventStatus,
      realEventType: this.realEventStatus,
      equipType: this.equipType,
      confirmType: this.judgment,
      searchType: this.searchType,
      searchString: this.filter || "",
      startDate: this.searchStartDate,
      endDate: this.searchEndDate,
      tunnelBoundType:
        this.searchBound == "ALL"
          ? this.tunnelCodeStatus
          : this.searchBound == 0
          ? "E-" + this.tunnelCodeStatus
          : "S-" + this.tunnelCodeStatus,
    };
    await this.refetchData();
  },
  watch: {
      '$i18n.locale'(newLocale, oldLocale) {
        this.setUpdateDate()
        this.doFilter();

        this.eventStatusOptions[0] = { text : this.$t('label.L0021'), data : 999};
        this.eventStatusOptions[1] = { text : this.$t('label.L0022'), data : 2};
        this.eventStatusOptions[2] = { text : this.$t('label.L0023'), data : 3};
        this.eventStatusOptions[3] = { text : this.$t('label.L0025'), data : 4};
        this.eventStatusOptions[4] = { text : this.$t('label.L0026'), data : 9};
        this.eventStatusOptions[5] = { text : this.$t('label.L0027'), data : 1};

        this.resultOptions[0] = { text : this.$t('label.L0021'), data : 999};
        this.resultOptions[1] = { text : this.$t('label.L0041'), data : 1};
        this.resultOptions[2] = { text : this.$t('label.L0042'), data : 2};
        this.resultOptions[3] = { text : this.$t('label.L0089'), data : 3};
        this.resultOptions[4] = { text : this.$t('label.L0090'), data : 4};

        this.realEventStatusOptions[0] = { text : this.$t('label.L0021'), data : 999};
        this.realEventStatusOptions[1] = { text : this.$t('label.L0095'), data : 0};
        this.realEventStatusOptions[2] = { text : this.$t('label.L0022'), data : 2};
        this.realEventStatusOptions[3] = { text : this.$t('label.L0023'), data : 3};
        this.realEventStatusOptions[4] = { text : this.$t('label.L0025'), data : 4};
        this.realEventStatusOptions[5] = { text : this.$t('label.L0026'), data : 9};
        this.realEventStatusOptions[6] = { text : this.$t('label.L0027'), data : 1};

        this.equipTypeOptions[0] = { text : this.$t('label.L0021'), data : "ALL"};
        this.equipTypeOptions[1] = { text : this.$t('label.L0031'), data : "C"};
        this.equipTypeOptions[2] = { text : this.$t('label.L0029'), data : "R"};

    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.left-text {
  text-align: left !important;
}
.padding-base-12 {
  /* padding-right: 1rem !important; */
  /* padding-left: 1rem !important; */
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/* $t("label.L0047")에서 시간, 년수에 사용자 입력 막기 위해 사용  */
.numInputWrapper .numInput {
  pointer-events: none !important;
}
.table td,
.table th {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  text-align: center;
}
.table td.textRight {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  text-align: right !important;
}

/* .scrollWrapper tbody {
  height: 395px;
  overflow-y: overlay;

} */
</style>
