import { ref, watch, computed, reactive, toRefs } from "@vue/composition-api";
import api from "@/libs/api.js";
// Notification
import { useToast } from "vue-toastification/composition";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

export default function useUsersList() {
  const toast = useToast();

  const maintable = ref(null);

  const tableColumns = [
    // { key: "check", label: "", thStyle: { padding: "0.72rem", width: "3%" } },
    {
      key: "idx",
      label: i18n.t("label.L0055"),
      // thStyle: { padding: "0.72rem", width: "3%" },
      thStyle: { width: "3%", verticalAlign: "middle" },
    },
    {
      key: "occurTime",
      label: i18n.t("label.L0144"),
      thStyle: { width: "8%", verticalAlign: "middle" },
    },
    {
      key: "location",
      label: i18n.t("label.L0017"),
      thStyle: { width: "12%", verticalAlign: "middle" },
    },
    {
      key: "tech",
      label: i18n.t("label.L0043"),
      thStyle: { width: "5%", verticalAlign: "middle" },
    },
    // {
    //   key: "cameraId",
    //   label: i18n.t("label.L0069"),
    //   thStyle: { width: "8%", verticalAlign: "middle" },
    // },
    {
      key: "equipName",
      label: i18n.t("label.L0054"),
      thStyle: { width: "8%", verticalAlign: "middle" },
    },
    {
      key: "linkCode",
      label: i18n.t("label.L0087"),
      thStyle: { width: "6%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return getDFunction(item.linkCode);
      },
    },
    {
      key: "position",
      label: i18n.t("label.L0052"),
      class: "textRight",
      thStyle: { width: "5%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return item.position.toFixed(2) * 1000 + " m";
      },
    },
    {
      key: "dEventType",
      label: i18n.t("label.L0048"),
      thStyle: { width: "10%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return getFunction(item.dEventType);
      },
    },
    // { key: "rEventType", label: '실제 돌발 유형',thStyle: { width: "11%" } , formatter: (value, key, item) => {
    //   return getFunction(item.rEventType, item.confirmType)
    // },},
    {
      key: "rEventType",
      label: i18n.t("label.L0049"),
      thStyle: { width: "10%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return getFunction(item.rEventType);
      },
    },
    {
      key: "confirmType",
      label: i18n.t("label.L0050"),
      thStyle: { width: "5%", verticalAlign: "middle" },
      formatter: (value, key, item) => {
        return getTFunction(item.confirmType);
      },
    },
    {
      key: "confirm",
      label: i18n.t("label.L0145"),
      thStyle: { width: "9%", verticalAlign: "middle" },
    },
    // {
    //   key: "memo",
    //   label: i18n.t("label.L0057"),
    //   // thStyle: { width: "5%" },
    //   thStyle: { width: "10%" },
    //   tdClass: "left-text",
    //   formatter: (value, key, item) => {
    //     return item.memo != null
    //       ? item.memo.length > 8
    //         ? item.memo.slice(0, 8) + "..."
    //         : item.memo
    //       : null;
    //   },
    // },
  ];
  const getFunction = (value, cfType) => {
    // if(cfType==0) return '미정'
    if (value == 0) return i18n.t("label.L0095");
    if (value == 1) return i18n.t("label.L0027");
    if (value == 2) return i18n.t("label.L0022");
    if (value == 3) return i18n.t("label.L0023");
    if (value == 4) return i18n.t("label.L0025");
    if (value == 6) return i18n.t("label.L0024");
    if (value == 9) return i18n.t("label.L0026");
    if (value == 11) return i18n.t("label.L0091");
    if (value == 12) return i18n.t("label.L0092");
    if (value == 13) return i18n.t("label.L0093");
    if (value == 14) return i18n.t("label.L0094");
    if (value == 999) return i18n.t("label.L0146");
  };
  const getTFunction = (value) => {
    // if (value == 0) return "미정";
    if (value == 1) return i18n.t("label.L0041");
    if (value == 2) return i18n.t("label.L0042");
    if (value == 3) return i18n.t("label.L0089");
    if (value == 4) return i18n.t("label.L0090");
    if (value == 999) return i18n.t("label.L0050");
  };

  const getDFunction = (value) => {
    if (value == "LNK-0450-CMT") return i18n.t("label.L0020");
    else return i18n.t("label.L0019");
  };
  //   selectedEventTypeString : {
  //     0 : () => this.$t("event.test.none"),
  //     1 : () => this.$t('dashboard.event.stop'),
  //     2 : () => this.$t('dashboard.event.pedestrian'),
  //     3 : () => this.$t('dashboard.event.reverse'),
  //     9 : () => this.$t('dashboard.event.congestion'),
  //     999 :  () => this.$t('dashboard.event.title')
  // },
  // event.position.toFixed(2)
  const rowNum = ref(10);
  const totalRows = ref(0);
  const currentPage = ref(1);
  const itemLength = ref(0);
  const perPageOptions = [10, 50, 100];
  const filter = ref("");
  const searchEndDate = ref(null);
  const searchStartDate = ref(null);
  const searchBound = ref("ALL"); //방면
  const tunnelCodeOptions = ref([]); // 터널명 셀렉트 박스
  const tunnelCodeStatus = ref("ALL"); // 터널명
  const eventStatus = ref(999); //돌발현황
  const realEventStatus = ref(999); //실제돌발현황
  const judgment = ref(999); //판정 결과
  const equipType = ref("ALL"); //장비 종류
  const searchType = ref("equipName"); 
  const linkCode = ref(""); //링크코드?
  const changeType = ref(1);
  const eventStatisticsToString = ref(null);
  const utils = {
    mergeJson: mergeJson,
    downloadFile: downloadFile,
  };
  const dataMeta = computed(() => {
    const localItemsCount = maintable.value
      ? maintable.value.localItems.length
      : 0;
    return {
      from: rowNum.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: rowNum.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });

  const refetchData = async () => {
    await getSelectBoxItems();
    maintable.value.refresh();
  };

  watch([currentPage, rowNum], () => {
    refetchData();
  });

  const getSelectBoxItems = async () => {
    try {
      const LanguageALLText = i18n.t("label.L0021");
      let selectBoxItems = [{ text: LanguageALLText, data: "ALL" }]; // 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSelectBoxItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          text: item.location,
          data: item.tunnelcode,
        };
        selectBoxItems.push(selectBoxItem);
      }
      tunnelCodeOptions.value = selectBoxItems;
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async () => {

    for (var i = 0; i < tableColumns.length; i++) {
      var eventTableColumns = tableColumns[i];
      if(i==0){
        eventTableColumns.label = i18n.t("label.L0055");
      }
      if(i==1){
        eventTableColumns.label = i18n.t("label.L0144");
      }
      if(i==2){
        eventTableColumns.label = i18n.t("label.L0017");
      }
      if(i==3){
        eventTableColumns.label = i18n.t("label.L0043");
      }
      if(i==4){
        eventTableColumns.label = i18n.t("label.L0054");
      }
      if(i==5){
        eventTableColumns.label = i18n.t("label.L0087");
      }
      if(i==6){
        eventTableColumns.label = i18n.t("label.L0052");
      }
      if(i==7){
        eventTableColumns.label = i18n.t("label.L0048");
      }
      if(i==8){
        eventTableColumns.label = i18n.t("label.L0049");
      }
      if(i==9){
        eventTableColumns.label = i18n.t("label.L0050");
      }
      if(i==10){
        eventTableColumns.label = i18n.t("label.L0145");
      }
    }

    try {
      var searchString = filter.value;
      if (searchType.value == "position" && searchString !== "") {
        searchString = Number(filter.value) / 1000;
      }

      const response = await api.getEventList({
        // boundType: searchBound.value,
        boundType:
          searchBound.value == "ALL"
            ? "LNK"
            : searchBound.value == 0
            ? "E-"
            : "S-",
        confirmType: judgment.value,
        equipType: equipType.value,
        endDate: searchEndDate.value,
        tunnelCode: tunnelCodeStatus.value,
        eventType: eventStatus.value,
        realEventType: realEventStatus.value,
        linkCode: linkCode.value,
        page: currentPage.value,
        pageSize: rowNum.value,
        searchString: searchString,
        searchType: searchType.value,
        startDate: searchStartDate.value,
        tunnelBoundType:
          searchBound.value == "ALL"
            ? tunnelCodeStatus.value
            : searchBound.value == 0
            ? "E-" + tunnelCodeStatus.value
            : "S-" + tunnelCodeStatus.value,
      });
      const { data } = response.data;
      const { page, total } = data.paging;

      currentPage.value = page;
      totalRows.value = total;
      itemLength.value = data.eventList.length;
      eventStatisticsToString.value = data.eventStatisticsToString;
      return data.eventList;
    } catch (err) {
      console.log(err);
    }
  };
  const deleteFunction = async (data) => {
    api.removeSchedule(data);
  };

  // 정오검사 시 비디오 다운로드
  const downLoadFunction = async (data) => {
    const response = await api.downloadVideo({
      no: data.item.no,
      // d_event_type : data.item.dEventType,
      // rec_video_url: data.item.recVideoUrl
      occurTime: data.item.occurTime,
      dEventType: data.item.dEventType,
      recVideoUrl: data.item.recVideoUrl,
    });
    return response;
  };

  //  비디오 리스트 다운로드
  const downLoadVideoListFunction = async (item) => {
    const response = await api.downloadVideo({
      no: item.no,
      // d_event_type : data.item.dEventType,
      // rec_video_url: data.item.recVideoUrl
      occurTime: item.occurTime,
      dEventType: item.dEventType,
      recVideoUrl: item.recVideoUrl,
    });
    return response;
  };

  // 체크된 항목 실제 돌발 유형 일괄 수정
  const updateREventTypeFunction = async (data) => {
    const response = await api.updateREventType(data);
    return response;
  };

  // email 테스트
  const emailTestFunction = async (data) => {
    const response = await api.sendEmail(data);
    return response;
  };

  const updateFunction = async (arr) => {
    for (let i = 0; i < arr.length; i++) {
      let event = arr[i];
      await api.updateEvent(event.no, event);
    }
  };
  const updateRowFunction = async (item) => {
    let data = {};
    data.rEventType = item.rEventType;
    data.confirmType = item.confirmType;
    data.memo = item.memo;
    const response = await api.updateEvent(item.no, data);
    return response;
  };

  const downloadExcelFunction = async (params, name) => {
    try {
      const response = await api.downloadEventExcel(params);
      await utils.downloadFile(response.data, name);
      return response.status;
    } catch (err) {
      console.log("downloadExcelFunction err : ", err);
    }
  };

  function mergeJson(source, target) {
    for (var key in target) {
      if (source.hasOwnProperty(key)) {
        var d = source[key];
        var td = target[key];
        if (typeof d == "object" && typeof td == "object") {
          if (Array.isArray(d)) {
            if (Array.isArray(td)) {
              d = d.concat(td);
            } else {
              d.push(td);
            }
          } else {
            d = mergeJson(d, td);
          }
        } else {
          source[key] = target[key];
        }
      } else {
        source[key] = target[key];
      }
    }

    return source;
  }

  function downloadFile(data, fileName) {
    let blob = new Blob([data]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE : IE 브라우저인경우 실행 (다른 브라우저와 동작 방식이 다르기 때문 )
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      let link = document.createElement("a"); // 다운로드를 위한 가상 태그 생성
      link.href = window.URL.createObjectURL(blob); // 사용자가 파일에 접근할 수 있도록 Blob 객체에 대한 주소 생성
      link.setAttribute("download", fileName); // 가상 태그에 다운로드 기능 추가
      link.click(); // 가상태그 클릭으로 파일에 접근해 다운로드
      link.remove(); // 가상 태그 삭제
    }
  }
  return {
    deleteFunction,
    refetchData,
    getList,
    tableColumns,
    rowNum,
    totalRows,
    currentPage,
    dataMeta,
    perPageOptions,
    filter,
    searchEndDate,
    searchStartDate,
    maintable,
    updateFunction,
    emailTestFunction,
    searchBound,
    tunnelCodeStatus,
    eventStatus,
    realEventStatus,
    judgment,
    equipType,
    searchType,
    linkCode,
    changeType,
    eventStatisticsToString,
    downloadExcelFunction,
    itemLength,
    downLoadFunction,
    updateRowFunction,
    downLoadVideoListFunction,
    updateREventTypeFunction,
    getSelectBoxItems,
    tunnelCodeOptions,
  };
}
